export const environment = {
  production: true,
  environmentName: 'development',
  hmr: false,
  baseHost: 'developmentslapfive.com',
  cookieHost: 'developmentslapfive.com',
  apiUrl: 'https://app.developmentslapfive.com',
  auth0CallbackUrl: 'https://app.developmentslapfive.com',
  auth0ClientID: 'blu7Cw6sna2166hxZgeJ42yAOsrFQGyx',
  auth0CustomDomain: 'slapfive-development.us.auth0.com',
  auth0Tenant: 'slapfive-development',
  auth0LogoutUrl: `https://www.slapfive.com`,
  auth0LoginRedirUrl: '/client/home',
  auth0SessionTimeoutUrl: `https://app.developmentslapfive.com/session-timeout`,
  auth0SessionWarningSeconds: 3600,
  auth0SessionTimeoutSeconds: 120,
  gqlUrl: 'https://gql.developmentslapfive.com/v1/graphql',
  gqlWsUrl: 'wss://gql.developmentslapfive.com/v1/graphql',
  subdomain_redir: false,
  log_level: 'debug',
  google_storage_baseUrl: 'https://www.googleapis.com/upload/storage/v1/b/slapfive/o?uploadType=media',
  google_storage_folders: {
    audio: 'a',
    video: 'v',
    image: 'i'
  },
  auth0Audience: 'https://slapfive-development.us.auth0.com/api/v2/',
  sentryDsn: 'https://d27e8c7bb795426ab017ac1c27bcb499@o211966.ingest.sentry.io/1338037',
  gcsServingUrl: 'https://media.slapfive.com',
};
